import React from 'react';
import jamesProfile from './james-profile-photo.jpg'
import './icofont.min.css'

function App() {
  return (
    <>
    <div className="row header-section middle-xs">
        <div className="col-xs-12 col-md-6">
          <span className="hello-emoji" role="img" aria-label="Hello!">👋</span>
          <h1>Hi, I'm James.</h1>
          <p>I live in Chicago where I design and code web and mobile apps.</p>
          <p>I'm currently the UX designer and frontend developer at <a href="https://www.twistedroad.com" rel="noopener noreferrer" target="_blank">Twisted Road</a>.</p>
          <p>Please let me build you something (for money).</p>
          <div className="social-callouts">
            <a href="https://www.linkedin.com/in/james-winston-1b2ab324/">
              <i class="icofont-linkedin icofont-2x"></i>
            </a>
            <a href="https://dribbble.com/jameswinston58">
              <i class="icofont-dribbble icofont-2x"></i>
            </a>
            <a href="https://www.instagram.com/james._.winston/">
              <i class="icofont-instagram icofont-2x"></i>
            </a>
            <a href="https://vimeo.com/user3882626">
              <i class="icofont-vimeo icofont-2x"></i>  
            </a>
            <a href="mailto:jameswinston58@gmail.com">
              <i class="icofont-envelope icofont-2x"></i>  
            </a>

            

          </div>
        </div>
        <div className="col-xs-12 col-md-6 james-image-column">
          <img src={jamesProfile} alt="James Winston" />
        </div>
    </div>
    <div className="row resume-wrapper">
      <div className="col-xs-12">
      <h2>Case Studies</h2>
      </div>
    </div>
    <div className="row resume-wrapper">
      <div className="col-xs-12">
      <h2>Resume</h2>
        <div className="resume-section">
        <h3>Fulltime Experience</h3>
        <p>
          <span>Frontend Developer & UX Designer</span>
          Twisted Road | Feb 2019 - Current
        </p>
        <p>
          <span>Multidiscipline Designer & Developer</span>
          Freelance | May 2016 - Feb 2019
        </p>        
        <p>
          <span>After Effects Instructor</span>
          Northern Illinois University | Aug 2013 - Current
        </p>    
        <p>
          <span>Web Developer and Designer</span>
          Nothern Illinois University | Feb 2013 - May 2016
        </p>  
        <p>
          <span>Interactive Media Developer</span>
          Forty Two Eighty Nine | Feb 2012 - Feb 2013
        </p>  
        </div>
        <div className="resume-section"> 
        <h3>Education</h3>     
        <p>
          <span>M.A. Communication & Media Studies</span>
          Northern Illinois University | 2012
        </p> 
        <p>
          <span>B.S. Information Tecnology</span>
          Arkansas Tech University | 2008
        </p> 
      </div>
      <div className="resume-section"> 
        <h3>Some Past Clients</h3>     
          <ul>
            <li>Addus Healthcare</li>
            <li>Engage Target Media</li>
            <li>Collison Hub</li>
            <li>Northern Illinois University</li>
            <li>TCS Education System</li>
            <li>The Chicago School of Professional Psychology</li>
            <li>Saybrook University</li>
            <li>Fam - Buy, Sell, and Trade</li>
            <li>Forty Two Eighty Nine</li>
            <li>University of Wisconsin - Madison</li>
            <li>Pacific Oaks College</li>
            <li>Axalta Refinishing</li>
            <li>Gerber Collison</li>
            <li>Warrior Poets Productions</li>
            <li>PSAV</li>
            <li>Trouw Nutrition</li>
            <li>Twisted Road</li>
            <li>Mason Interactive</li>
            <li>nOCD</li>
          </ul>
        </div>
        <div className="resume-section"> 
        <h3>More Stuff</h3> 
        <p>Lookng for a portfolio? Check out my <a href="https://dribbble.com/jameswinston58">Dribbble</a>.</p>
        <p>I'm a board member of this rad orgnization call <a href="https://www.forthewinproject.com">The For The Win Project</a>.</p>
        <p>You might have seen our work on <a href="https://www.upworthy.com/darth-vader-girl-is-the-most-heartwarming-hospital-story-youll-see-today">Upworthy</a>, <a href="https://www.nbcnews.com/nightly-news/video/organization-gives-children-with-challenges-a-chance-to-be-movie-stars-971459139748">NBC Nightly News</a>, or <a href="https://www.facebook.com/watch/?v=1470745556320335">NowThis</a>.</p>
        <p>I once met John Waters in a dive bar.</p>
        <p>Here is my <a href="https://www.imdb.com/name/nm4591172/">IMDB page</a>.</p>
        <p>Here's a playlist you can judge me on or fall in love with me through.</p>
        <br />
        <iframe  title="spotifyiframe" src="https://open.spotify.com/embed/playlist/2Z8LDxhWD7dc870nPK1uaH" width="350" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
